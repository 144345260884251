import React, { FC, useCallback } from 'react'
import { useGetMoviesByGenreIdWithPaginationQuery } from '@/modules/genre/genre.api'
import MovieSlider, { MovieSliderProps } from '@/modules/films/components/MovieSlider'
import Button from '@/components/Button'
import { useNavigate } from 'react-router-dom'
import { ContentPaths, Paths } from '@/core/router/router.paths'

interface FetchMoviesByGenreId extends MovieSliderProps {
  id: string
}

export const FetchMoviesByGenreId: FC<FetchMoviesByGenreId> = ({ id, ...props }) => {
  const { data, isLoading } = useGetMoviesByGenreIdWithPaginationQuery({
    genreId: id,
    relations: {
      movie: false,
      trailer: false,
      backgrounds: true,
    },
    paginate: {
      page: 1,
      limit: 10,
    },
  })
  const navigate = useNavigate()

  const handleOnAction = useCallback(() => {
    if (!id) return

    navigate(`${Paths.CONTENT}/${ContentPaths.GENRES}/${id}`, {
      state: { movies: true, serials: false },
    })
  }, [id])

  return (
    <MovieSlider
      height={447}
      isLoading={isLoading}
      data={data?.items}
      specificAction={({ className }) => (
        <Button
          buttonClassName={className}
          onButtonClick={handleOnAction}
          onEnterPress={handleOnAction}
        >
          Смотреть всё
        </Button>
      )}
      {...props}
    />
  )
}

export default FetchMoviesByGenreId
