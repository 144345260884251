import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '@/core/store'

import { setSeason } from '@/modules/serials/serials.actions'
import { useGetOneContentMediaViewQuery } from '@/modules/syncMediaView/syncMediaView.api'

import FetchEpisodesForChosenSeasonAndPresetEpisode from '@/modules/serials/component/FetchEpisodesForChosenSeasonAndPresetEpisode'

const FetchViewInfoAndPresetSeasonAndEpisode = () => {
  const { id: contentId } = useParams()

  const dispatch = useAppDispatch()

  const seasons = useAppSelector((state) => state.serial.seasons)

  const isSeasonsLinkedToCurrentContent = useMemo(() => {
    if (!contentId || !seasons) return false
    return !seasons.find((season) => season.contentId !== contentId)
  }, [seasons, contentId])

  const {
    data: info,
    error,
    isLoading,
  } = useGetOneContentMediaViewQuery(
    {
      contentId: contentId || '',
    },
    {
      skip: !contentId,
    },
  )

  useEffect(() => {
    if (!error || !isSeasonsLinkedToCurrentContent || !seasons || isLoading) return

    const firstSeason = seasons[0]

    if (!firstSeason) return

    dispatch(setSeason(firstSeason))
  }, [error, seasons, isSeasonsLinkedToCurrentContent])

  useEffect(() => {
    if (!isSeasonsLinkedToCurrentContent || isLoading || !seasons) return

    if (!info || !info.resource) {
      const firstSeason = seasons[0]

      if (!firstSeason) return

      dispatch(setSeason(firstSeason))

      return
    }

    const seasonId = info.resource.seasonId
    const seasonNumber = info.resource.season

    const season = seasons.find((s) => s.id === seasonId || s.number === seasonNumber)

    if (!season) return

    dispatch(setSeason(season))
  }, [info, seasons, isLoading, isSeasonsLinkedToCurrentContent])

  return (
    <FetchEpisodesForChosenSeasonAndPresetEpisode
      episodeId={info?.resourceId}
      episodeNumber={info?.resource?.episode}
    />
  )
}

export default FetchViewInfoAndPresetSeasonAndEpisode
