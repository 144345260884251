import React, { useMemo } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Button from '@/components/Button'
import List from '@/components/List'

import { useAppDispatch, useAppSelector } from '@/core/store'

import { setSeason } from '@/modules/serials/serials.actions'

import { SeasonBaseModel } from '@/models/serial.model'

const SeasonsList = () => {
  const dispatch = useAppDispatch()

  const seasons = useAppSelector((state) => state.serial.seasons)
  const season = useAppSelector((state) => state.serial.season)

  const handleOnPress = (season: SeasonBaseModel) => {
    dispatch(setSeason(season))
  }

  const preparedSeasons = useMemo(() => {
    if (!seasons) return []
    return seasons
  }, [seasons])

  return (
    <List
      focusProps={{ saveLastFocusedChild: true }}
      className={styles.List}
      throttleScrollDelay={50}
    >
      {preparedSeasons.map((el) => {
        return (
          <Button
            // todo: add logic for disable button
            // disabled={streamsStatus === APIStatus.PENDING}
            key={el.id}
            onButtonClick={() => handleOnPress(el)}
            onEnterPress={() => handleOnPress(el)}
            focusWrapperClassName={styles.SeasonButtonFocusWrapper}
            buttonClassName={classNames(styles.SeasonButton, season?.id === el.id && styles.Active)}
            focusClassName={styles.Focused}
          >
            <>Сезон {el.number}</>
          </Button>
        )
      })}
    </List>
  )
}

export default SeasonsList
