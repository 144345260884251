import React, { memo, useCallback, useEffect, useRef } from 'react'

import styles from '@/modules/home/views/Home/styles.module.scss'

import TvChosenChannel from '@/modules/tv/components/TvChosenChannel'
import ChosenContent from '@/modules/content/components/ChosenContent'
import FetchAndRenderPromotesContent from '@/modules/promotes/components/FetchAndRenderPromotesContent'

import { useAppDispatch, useAppSelector } from '@/core/store'

import { resetChosenCard } from '@/modules/content/content.actions'
import { resetEpisode, resetSeason } from '@/modules/serials/serials.actions'
import { ChannelModel } from '@/models/channel.model'
import { resetChosenChannel, setFocusedChannel } from '@/modules/tv/tv.actions'
import AllCurrentProgramsFetcher from '@/modules/tv/components/AllCurrentProgramsFetcher'

const HomeHeader = () => {
  const dispatch = useAppDispatch()

  const card = useAppSelector((state) => !!state.content.chosenCard)
  const chosenChannel = useAppSelector((state) => !!state.tv.chosenChannel)

  const shouldAutoFocusPromoRef = useRef(true)

  const handleOnAutoFocus = useCallback(() => {
    shouldAutoFocusPromoRef.current = false
  }, [])

  useEffect(() => {
    dispatch(resetChosenChannel())
    dispatch(resetChosenCard())
    dispatch(resetSeason())
    dispatch(resetEpisode())
  }, [])

  return (
    <div className={styles.Home__Header}>
      {chosenChannel ? (
        <TvChosenChannel playerId={'HomeTvPlayer'} autoFocus />
      ) : card ? (
        <ChosenContent />
      ) : (
        <>
          <FetchAndRenderPromotesContent
            contentType={'resources'}
            onAutoFocus={handleOnAutoFocus}
            shouldAutoFocusPromoCard={shouldAutoFocusPromoRef.current}
          />
          <AllCurrentProgramsFetcher />
        </>
      )}
      <ResolveShouldChangeFocusedChannelBeforeUnmount />
    </div>
  )
}

const ResolveShouldChangeFocusedChannelBeforeUnmount = () => {
  const dispatch = useAppDispatch()

  const chosenChannel = useAppSelector((state) => state.tv.chosenChannel)
  const chosenChannelRef = useRef<ChannelModel | null>(null)

  chosenChannelRef.current = chosenChannel

  useEffect(() => {
    return () => {
      if (!chosenChannelRef.current) return

      dispatch(setFocusedChannel(chosenChannelRef.current))
    }
  }, [])

  return null
}

export default memo(HomeHeader)
