import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '@/core/store/helpers'
import { EpisodeBaseModel, SeasonBaseModel } from '@/models/serial.model'

export const setSeasons = createAction(
  'serialSlice/setSeasons',
  withPayloadType<SeasonBaseModel[]>(),
)
export const setEpisodes = createAction(
  'serialSlice/setEpisodes',
  withPayloadType<{ seasonId: string; episodes: EpisodeBaseModel[] }>(),
)

export const resetSeasonsAndEpisodes = createAction('serialSlice/resetSeasonsAndEpisodes')

export const setSeason = createAction('serialSlice/setSeason', withPayloadType<SeasonBaseModel>())
export const resetSeason = createAction('serialSlice/resetSeason')

export const setEpisode = createAction(
  'serialSlice/setEpisode',
  withPayloadType<EpisodeBaseModel>(),
)
export const resetEpisode = createAction('serialSlice/resetEpisode')

export const setPlayerFS = createAction('serialSlice/setPlayerFS', withPayloadType<boolean>())
