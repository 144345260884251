import React, { FC, memo, useCallback, useMemo } from 'react'

import { useNavigate } from 'react-router-dom'

import Button from '@/components/Button'
import NeedPackagesModal from '@/modules/content/components/NeedPackagesModal'

import { useAppSelector } from '@/core/store'

import { getSeasonEpisodeTitle } from '@/modules/serials/serials.helpers'

import { checkIsRightsErrorModel, RightsErrorModel } from '@/models/rightsError.model'
import { SerialModel } from '@/models/serial.model'
import { ContentPaths, Paths } from '@/core/router/router.paths'

interface PlayChosenEpisodeActionProps {
  disabled?: boolean
  className?: string
  rightsError?: RightsErrorModel
  rightsLoading?: boolean
}

const PlayChosenEpisodeAction: FC<PlayChosenEpisodeActionProps> = ({
  disabled,
  className,
  rightsLoading,
  rightsError,
}) => {
  const episode = useAppSelector((state) => state.serial.episode)
  const seasons = useAppSelector((state) => state.serial.seasons)
  const serial = useAppSelector((state) => state.content.chosenCard) as SerialModel

  const navigate = useNavigate()

  const handleOnPress = useCallback(() => {
    if (disabled) return
    if (rightsError) {
      return
    }

    navigate(`${Paths.CONTENT}/${ContentPaths.SERIAL_PLAYER}`)
  }, [rightsError, episode, serial, disabled])

  const title = useMemo(() => {
    return getSeasonEpisodeTitle(episode, seasons)
  }, [seasons, episode])

  if (!episode) return null

  return (
    <>
      {rightsError && checkIsRightsErrorModel(rightsError) && (
        <NeedPackagesModal
          packages={rightsError.data.message}
          trigger={(onTrigger) => (
            <Button
              // disabled={disabled || loadingRights /*|| streamsLoading*/}
              onEnterPress={onTrigger}
              onButtonClick={onTrigger}
              buttonClassName={className}
              autoFocus
              loading={rightsLoading}
            >
              <div>
                <span style={{ display: 'block', lineHeight: 1.2 }}>{title}</span>
                <span
                  style={{
                    display: 'block',
                    lineHeight: 1,
                    fontSize: '28px',
                    opacity: 0.6,
                    textAlign: 'left',
                  }}
                >
                  <small>нужна подписка</small>
                </span>
              </div>
            </Button>
          )}
        />
      )}
      {!rightsError && (
        <Button
          // disabled={disabled || loadingRights}
          onEnterPress={handleOnPress}
          onButtonClick={handleOnPress}
          buttonClassName={className}
          autoFocus
          loading={rightsLoading}
        >
          <span style={{ display: 'block' }}>{title}</span>
        </Button>
      )}
    </>
  )
}

export default memo(PlayChosenEpisodeAction)
