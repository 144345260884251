import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useAppDispatch } from '@/core/store'
import { dispatchContentStatusEvent } from '@/core/customEvents/content'

import { useGetContentByIdQuery } from '@/modules/content/content.api'
import { setChosenCard } from '@/modules/content/content.actions'

const ContentFetcher = () => {
  const { id } = useParams()

  const dispatch = useAppDispatch()

  const {
    data: content,
    isLoading,
    isFetching,
  } = useGetContentByIdQuery({ contentId: id || '' }, { skip: !id })

  useEffect(() => {
    if (!isLoading && !isFetching) return
    dispatchContentStatusEvent({ status: false })
  }, [isLoading, isFetching])

  useEffect(() => {
    if (!content) return

    dispatch(setChosenCard(content))

    if (content.type === 'MOVIE' && 'movie' in content && content.movie) {
      dispatchContentStatusEvent({ status: true })
    }
  }, [content])

  return null
}

export default ContentFetcher
