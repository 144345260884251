import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@/core/store'

import { useLazyGetEpisodesBySerialIdAndSeasonIdQuery } from '@/modules/content/content.api'
import { setEpisodes } from '@/modules/serials/serials.actions'

const FetchUnloadedSeasonsEpisodes = () => {
  const dispatch = useAppDispatch()

  const seasons = useAppSelector((state) => state.serial.seasons)
  const episodes = useAppSelector((state) => state.serial.episodes)

  const [getEpisodes] = useLazyGetEpisodesBySerialIdAndSeasonIdQuery()

  useEffect(() => {
    if (!seasons) return

    const unloadedSeasonsEpisodes = seasons.filter((season) => {
      return !episodes[season.id]
    })

    if (unloadedSeasonsEpisodes.length === 0) return

    const promises = unloadedSeasonsEpisodes.map((season) => {
      return getEpisodes({ serialId: season.serialId, seasonId: season.id })
        .unwrap()
        .then((episodes) => {
          dispatch(setEpisodes({ seasonId: season.id, episodes }))
        })
    })

    Promise.all(promises).catch(console.error)
  }, [seasons])

  return null
}

export default FetchUnloadedSeasonsEpisodes
