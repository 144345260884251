import React, { useEffect } from 'react'

import styles from './styles.module.scss'

import Slider from '@/components/Slider'
import DefaultFocusWrapper from '@/components/Slider/DefaultFocusWrapper'
import ActorsAndGenres from '@/modules/content/components/ActorsAndGenres'
import ContentFetcher from '@/modules/content/components/ContentFetcher'
import GenresByContentSliders from '@/modules/content/components/GenresByContentSliders'
import ChosenMovieActionsWithSkeleton from '@/modules/films/components/ChosenMovieActionsWithSkeleton'

const Movie = () => {
  useEffect(() => {
    const desc = document.getElementById('content-description')
    if (desc) {
      desc.dataset.full = 'true'
    }
    return () => {
      if (desc) {
        desc.dataset.full = 'false'
      }
    }
  }, [])
  return (
    <div id={'movie-page'} className={styles.Movie}>
      <div className={styles.Movie__Footer}>
        <Slider className={styles.FocusWrapper}>
          <Slider.Vertical>
            {[
              <DefaultFocusWrapper key={0} withoutChildProps className={styles.ActionsFocusWrapper}>
                <ActorsAndGenres className={styles.AdditionalInfo} />

                <ChosenMovieActionsWithSkeleton />
              </DefaultFocusWrapper>,
              <GenresByContentSliders key={'sliders'} type={'movie'} />,
            ]}
          </Slider.Vertical>
        </Slider>
      </div>
      <div id={'trailer'} />
      <div id={'movies-player'} />
      <ContentFetcher />
    </div>
  )
}

export default Movie
