import React from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import TvPlayer from '@/modules/tv/components/TvPlayer'
import ProgramContainer from '@/modules/tv/components/ProgramContainer'
import ChangedChannelStreamsLoader from '@/modules/tv/components/ChangedChannelStreamsLoader'

import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation'

interface TvChosenChannelProps {
  playerId?: string
  autoFocus?: boolean
}

const TvChosenChannel = ({ playerId, autoFocus }: TvChosenChannelProps) => {
  const { focusKey, ref } = useFocusable()

  return (
    <FocusContext.Provider value={focusKey}>
      <div ref={ref} className={styles.TvChosenChannel}>
        <ProgramContainer />
        <div className={classNames(styles.PlayerContainer)}>
          <TvPlayer playerId={playerId} autoFocus={autoFocus} />
        </div>
      </div>
      <ChangedChannelStreamsLoader />
    </FocusContext.Provider>
  )
}

export default TvChosenChannel
