import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useMemo } from 'react'
import { useLazyContentRightsCheckQuery } from '@/modules/content/content.api'
import { useAppSelector } from '@/core/store'
import { checkIsRightsErrorModel } from '@/models/rightsError.model'

export const useCheckRights = (type: 'serial' | 'movie') => {
  const { id } = useParams()

  const episode = useAppSelector((state) => state.serial.episode)
  const chosenCard = useAppSelector((state) => state.content.chosenCard)

  const rightsParams = useMemo(() => {
    return {
      contentId: id!,
    }
  }, [id])

  const [
    contentRightsCheck,
    { error: rightsError, isLoading: loadingRights, isFetching: rightsFetching },
  ] = useLazyContentRightsCheckQuery()

  useEffect(() => {
    if (!id || !chosenCard || type !== 'movie') return

    contentRightsCheck(rightsParams)
  }, [rightsParams])

  useEffect(() => {
    if (!episode || !id || !episode.id || type !== 'serial') return

    contentRightsCheck({
      contentId: id,
    })
  }, [episode])

  const loading = useMemo(() => {
    return loadingRights || rightsFetching
  }, [loadingRights, rightsFetching])

  const handleRightsError = useCallback((e: unknown) => {
    if (checkIsRightsErrorModel(e)) {
      //   todo: logic for checked(typed) rights errors
      return
    }
    //   todo: logic for another errors
  }, [])

  useEffect(() => {
    if (!rightsError) return

    handleRightsError(rightsError)
  }, [rightsError])

  return useMemo(() => {
    return {
      rightsError: checkIsRightsErrorModel(rightsError) ? rightsError : undefined,
      rightsLoading: loading,
    }
  }, [loading, rightsError])
}
