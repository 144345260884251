import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '@/core/store'
import { resetSeasonsAndEpisodes, setSeasons } from '@/modules/serials/serials.actions'

import { useGetContentByIdQuery, useGetSeasonsBySerialIdQuery } from '@/modules/content/content.api'

import { SerialModel } from '@/models/serial.model'

const FetchSeasons = () => {
  const { id } = useParams()

  const dispatch = useAppDispatch()

  const seasonsFromState = useAppSelector((state) => state.serial.seasons)

  const isSeasonsLoaded = useMemo(() => {
    if (!id) return false
    if (!seasonsFromState) return false

    let seasonsLoaded = false

    seasonsFromState.forEach((season) => {
      seasonsLoaded = season.contentId === id
    })

    return seasonsLoaded
  }, [seasonsFromState, id])

  const { data: content } = useGetContentByIdQuery({ contentId: id || '' }, { skip: !id })

  const contentAsSerial = content as SerialModel

  const {
    data: seasons,
    error,
    refetch,
  } = useGetSeasonsBySerialIdQuery(
    {
      serialId: contentAsSerial?.serial?.id || '',
    },
    {
      skip:
        !contentAsSerial ||
        !contentAsSerial.serial ||
        !contentAsSerial.serial.id ||
        isSeasonsLoaded,
    },
  )

  useEffect(() => {
    if (!error) return

    refetch()
  }, [error])

  useEffect(() => {
    if (!seasons || isSeasonsLoaded) return
    dispatch(resetSeasonsAndEpisodes())
    dispatch(setSeasons(seasons))
  }, [seasons])

  return null
}

export default FetchSeasons
