import React, { FC } from 'react'

import styles from './styles.module.scss'

import Modal, { ModalProps } from '@/components/Modal'
import SeasonsList from '@/modules/serials/component/SeasonsList'
import EpisodesList from '@/modules/serials/component/EpisodesList'
import CircleButton from '@/components/CircleButton'

import { useAppSelector } from '@/core/store'
import FetchUnloadedSeasonsEpisodes from '@/modules/serials/component/FetchUnloadedSeasonsEpisodes'

interface ChoseSeasonEpisodeModalProps extends Omit<ModalProps, 'render'> {}

const ChoseSeasonEpisodeModal: FC<ChoseSeasonEpisodeModalProps> = (props) => {
  const card = useAppSelector((state) => state.content.chosenCard)

  if (!card) return null
  return (
    <Modal
      {...props}
      autoFocus={false}
      hideCloseButton
      render={(onClose) => {
        return (
          <div className={styles.SeasonOrEpisodeSelector}>
            <div className={styles.Header}>
              <CircleButton onButtonClick={onClose} onEnterPress={onClose}>
                <i className='icon-fill-arrow-left' />
              </CircleButton>
              <h2 className={styles.Title}>{card.title}</h2>
            </div>
            <div className={styles.SeasonOrEpisodeSelector__List}>
              <SeasonsList />
              <EpisodesList onClose={onClose} />
            </div>
            <FetchUnloadedSeasonsEpisodes />
          </div>
        )
      }}
    />
  )
}

export default ChoseSeasonEpisodeModal
