export const dispatchContentStatusEvent = (details: { status: boolean }) => {
  const contentReadyEvent = new CustomEvent('contentStatus', {
    detail: {
      status: details.status,
    },
  })

  document.dispatchEvent(contentReadyEvent)
}

export const subscribeToContentStatusEvent = (cb: (e: Any) => void) => {
  document.addEventListener('contentStatus', cb)

  return () => {
    document.removeEventListener('contentStatus', cb)
  }
}
