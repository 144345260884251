import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '@/core/store'

import { useGetEpisodesBySerialIdAndSeasonIdQuery } from '@/modules/content/content.api'
import { setEpisode, setEpisodes } from '@/modules/serials/serials.actions'
import { dispatchContentStatusEvent } from '@/core/customEvents/content'

interface Props {
  episodeId?: string
  episodeNumber?: number
}

const FetchEpisodesForChosenSeasonAndPresetEpisode = ({ episodeNumber, episodeId }: Props) => {
  const { id: contentId } = useParams()

  const dispatch = useAppDispatch()

  const season = useAppSelector((state) => state.serial.season)
  const episode = useAppSelector((state) => state.serial.episode)
  const episodesMap = useAppSelector((state) => state.serial.episodes)

  const isEpisodePresetEnded = useRef(!!episode && episode.contentId === contentId)
  isEpisodePresetEnded.current = !!episode && episode.contentId === contentId

  const seasonIdRef = useRef(season?.id)
  seasonIdRef.current = season?.id

  const {
    data: episodes,
    error,
    refetch,
  } = useGetEpisodesBySerialIdAndSeasonIdQuery(
    { seasonId: season?.id || '', serialId: season?.serialId || '' },
    {
      skip: !season || season.contentId !== contentId || !!episodesMap[season?.id],
    },
  )

  useEffect(() => {
    if (!error) return

    refetch()
  }, [error])

  useEffect(() => {
    if (!episode) return
    if (episode.contentId !== contentId) return

    dispatchContentStatusEvent({ status: true })
  }, [episode])

  useEffect(() => {
    if (!episodes || !seasonIdRef.current) return

    const seasonId = seasonIdRef.current

    dispatch(setEpisodes({ episodes, seasonId }))

    if (isEpisodePresetEnded.current) return

    if (!episodeId && !episodeNumber) {
      const firstEpisode = episodes[0]
      if (!firstEpisode) return
      dispatch(setEpisode(firstEpisode))
      dispatchContentStatusEvent({ status: true })
      return
    }

    const episode = episodes.find((e) => {
      return (
        e.id === episodeId ||
        (episodeNumber !== null && episodeNumber !== undefined && e.number === episodeNumber)
      )
    })

    if (!episode) return
    dispatch(setEpisode(episode))
    dispatchContentStatusEvent({ status: true })
    return
  }, [episodes, episodeId, episodeNumber])

  return null
}

export default FetchEpisodesForChosenSeasonAndPresetEpisode
