import { createSlice } from '@reduxjs/toolkit'
import { EpisodeBaseModel, SeasonBaseModel } from '@/models/serial.model'
import {
  resetEpisode,
  resetSeason,
  resetSeasonsAndEpisodes,
  setEpisode,
  setEpisodes,
  setSeason,
  setSeasons,
} from '@/modules/serials/serials.actions'

interface SerialsSlice {
  seasons: SeasonBaseModel[] | null
  episodes: Record<string, EpisodeBaseModel[]>
  season?: SeasonBaseModel | null
  episode?: EpisodeBaseModel | null
}

const initialState: SerialsSlice = {
  seasons: null,
  episodes: {} as Record<string, EpisodeBaseModel[]>,
  season: null,
  episode: null,
}

export const serialsSlice = createSlice({
  name: 'serialsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setSeasons, (state, action) => {
        state.seasons = action.payload
      })
      .addCase(setEpisodes, (state, action) => {
        const { episodes, seasonId } = action.payload
        state.episodes[seasonId] = episodes
      })
      .addCase(resetSeasonsAndEpisodes, (state) => {
        state.seasons = null
        state.episodes = {}
      })
      .addCase(setSeason, (state, action) => {
        state.season = action.payload
      })
      .addCase(resetSeason, (state) => {
        state.season = null
      })

      .addCase(setEpisode, (state, action) => {
        state.episode = action.payload
      })
      .addCase(resetEpisode, (state) => {
        state.episode = null
      })
  },
})
