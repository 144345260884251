import { ContentModel } from '@/models/content.model'
import { BasePosterModel, ImageModel, PosterType } from '@/models/poster.model'
import { CDN_IMAGES_API_BASE_URL } from '@/core/config'
import { BackgroundType } from '@/models/background.model'
import { GroupTypes } from '@/modules/feeds/feeds.api'
import { ContentPaths } from '@/core/router/router.paths'
import { PromotesResourceModel, ResourcePromotePoster } from '@/models/promotesResource.model'
import { ChannelModel } from '@/models/channel.model'
import { ActorBaseModel } from '@/models/actor.model'
import { GenreBaseModel } from '@/models/genre.model'

export enum PosterOrientation {
  HORIZONTAL = 'horizontalImage',
  VERTICAL = 'verticalImage',
}

export enum PosterExtension {
  JPEG = '.jpeg',
  PNG = '.png',
  WebP = '.webp',
}

export interface GetPosterUrlArgs<T> {
  content?: T
  orientation?: PosterOrientation
  extension?: PosterExtension
  query?: { [key: string]: number | string }
}

const getImage = <T extends Omit<BasePosterModel, 'type' | 'contentId'>>(
  poster?: T,
  orientation?: PosterOrientation,
) => {
  if (!poster) {
    return
  }
  return orientation && poster[orientation] && poster[orientation]?.hash
    ? poster[orientation]
    : poster.horizontalImage && poster.horizontalImage.hash
    ? poster.horizontalImage
    : poster.verticalImage && poster.verticalImage.hash
    ? poster.verticalImage
    : undefined
}

const getOrientedImage = <T extends Omit<BasePosterModel, 'type' | 'contentId'>>(
  poster?: T,
  orientation?: PosterOrientation,
) => {
  if (!poster) {
    return
  }
  return orientation && poster[orientation] && poster[orientation]?.hash
    ? poster[orientation]
    : undefined
}

const getOrientedUrl = (orientedImage: ImageModel, extension?: PosterExtension) => {
  return `${CDN_IMAGES_API_BASE_URL}/${orientedImage.folder}/${orientedImage.hash}${
    extension ? extension : ''
  }`
}

const getFinalUrl = (url: string, query?: { [key: string]: number | string }) => {
  if (!query) return url

  const keys = Object.keys(query)

  if (keys.length === 0) return url

  const mappedQuery = keys.map((key) => {
    return `${key}=${query[key]}`
  })

  return `${url}?${mappedQuery.join('&')}`
}

export interface GetPosterUrlFromPromotesV2Args<T> {
  promote?: T
  orientation?: PosterOrientation
  extension?: PosterExtension
  query?: { [key: string]: number | string }
}

export const getPromoteChannelResourcePosterUrl = <T extends PromotesResourceModel<ChannelModel>>({
  promote,
  orientation = PosterOrientation.HORIZONTAL,
  extension,
  query,
}: GetPosterUrlFromPromotesV2Args<T>): string | undefined => {
  if (!promote) return
  if (!promote.poster) return
  const poster = promote.poster

  const oriented = getImage<ResourcePromotePoster>(poster, orientation)

  if (!oriented) return

  const url = getOrientedUrl(oriented, extension)

  return getFinalUrl(url, query)
}

export const getPromoteContentResourcePosterUrl = <T extends PromotesResourceModel<ContentModel>>({
  promote,
  orientation = PosterOrientation.HORIZONTAL,
  extension,
  query,
}: GetPosterUrlFromPromotesV2Args<T>): string | undefined => {
  if (!promote) return
  if (!promote.poster)
    return getContentPosterUrl({ content: promote.resource, orientation, extension, query })

  const poster = promote.poster

  const oriented = getImage<ResourcePromotePoster>(poster, orientation)

  if (!oriented)
    return getContentPosterUrl({ content: promote.resource, orientation, extension, query })
  const url = getOrientedUrl(oriented, extension)

  return getFinalUrl(url, query)
}

export const getContentPosterUrl = <T extends ContentModel>({
  content,
  orientation = PosterOrientation.HORIZONTAL,
  extension,
  query,
}: GetPosterUrlArgs<T>): string | undefined => {
  if (!content) return

  const posters = content.posters
  if (!posters || !posters.length) return

  let customPoster: BasePosterModel | undefined
  let partnerPoster: BasePosterModel | undefined

  posters.forEach((poster) => {
    if (poster.type === PosterType.CUSTOM) {
      customPoster = poster
    }
    if (poster.type === PosterType.PARTNER) {
      partnerPoster = poster
    }
  })

  if (!customPoster && !partnerPoster) return

  // Try get custom poster oriented image and fallback image (without orientation bounding).
  // If it undefined try get partner poster oriented image and fallback image (without orientation bounding).

  const oriented =
    getOrientedImage(customPoster, orientation) || getOrientedImage(partnerPoster, orientation)

  const fallback = getImage(customPoster, orientation) || getImage(partnerPoster, orientation)

  if (!oriented && !fallback) return

  let url = ''

  if (oriented) {
    url = getOrientedUrl(oriented, extension)
  } else if (fallback) {
    url = getOrientedUrl(fallback, extension)
  }

  if (!url) return

  return getFinalUrl(url, query)
}

export const getContentBackgroundUrl = <T extends ContentModel>({
  content,
  extension = PosterExtension.JPEG,
  query,
}: GetPosterUrlArgs<T>): string | undefined => {
  if (!content) return

  const background =
    content.backgrounds?.find((el) => el.type === BackgroundType.CUSTOM) ||
    content.backgrounds?.find((el) => el.type === BackgroundType.PARTNER)

  if (!background || !background.image || !background.image.hash) return

  const url = getOrientedUrl(background.image, extension)

  return getFinalUrl(url, query)
}

export const getKeywordFromCard = <T extends ContentModel>(card?: T): string => {
  if (!card) return ''

  const year = card.year
  const arrOfCountries = card.countries

  if (!arrOfCountries && !year) return ''

  let finalArr: (string | number)[] = []
  if (year) {
    finalArr.push(year)
  }
  if (arrOfCountries) {
    const countries = arrOfCountries.map((country) => country.name)
    finalArr = finalArr.concat(countries)
  }

  return finalArr.join(', ')
}

export const getAgeFromContentCard = <T extends ContentModel>(card?: T): string => {
  if (!card || !card.age) return ''

  return card.age.title
}

export const getTitleFromContentCard = <T extends ContentModel>(card?: T): string => {
  if (!card || !card.title) return ''

  return card.title
}
export const getDescriptionFromContentCard = <T extends ContentModel>(card?: T): string => {
  if (!card || !card.description) return ''

  return card.description
}

export const getPreparedActors = (actors: ActorBaseModel[] = []) => {
  if (!actors || actors.length === 0) return ''

  return actors.map((el) => el.name).join(', ')
}

export const getPreparedGenresTitles = (genres: GenreBaseModel[] = []) => {
  if (!genres || genres.length === 0) return ''

  return genres.map((el) => el.title).join(', ')
}

export const resolvePathnameForGetFeedsResources = (pathname: string): GroupTypes => {
  if (pathname.includes(ContentPaths.MOVIES)) {
    return GroupTypes.MOVIE
  }

  if (pathname.includes(ContentPaths.SERIALS)) {
    return GroupTypes.SERIAL
  }

  return GroupTypes.CONTENT
}
