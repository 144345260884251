import React, { useEffect, useState } from 'react'
import styles from '@/modules/serials/views/Serial/styles.module.scss'

import ChosenContentActionsSkeleton from '@/modules/content/components/ChosenContentActionsSkeleton'
import PlayChosenEpisodeActionWithRightsCheck from '@/modules/serials/component/PlayChosenEpisodeActionWithRightsCheck'
import ChoseSeasonEpisodeAction from '@/modules/serials/component/ChoseSeasonEpisodeAction'
import TrailerAction from '@/modules/content/components/TrailerAction'
import FavoritesAction from '@/modules/favorites/components/FavoritesAction'

import { subscribeToContentStatusEvent } from '@/core/customEvents/content'

import { ContentType } from '@/models/content.model'

const ChosenSerialActionsWithSkeleton = () => {
  const [showSkeleton, setShowSkeleton] = useState(true)

  useEffect(() => {
    const handleContentStatus = (e: CustomEvent) => {
      setShowSkeleton(!e.detail.status)
    }

    return subscribeToContentStatusEvent(handleContentStatus)
  }, [])

  if (showSkeleton) {
    return <ChosenContentActionsSkeleton />
  }

  return (
    <div className={styles.ActionsContainer}>
      <PlayChosenEpisodeActionWithRightsCheck />
      <ChoseSeasonEpisodeAction className={styles.ActionButton} />
      <TrailerAction buttonClassName={styles.ActionButton} />
      <FavoritesAction className={styles.ActionButton} favoritesContentType={ContentType.SERIAL} />
    </div>
  )
}

export default ChosenSerialActionsWithSkeleton
