import React, { FC, useMemo } from 'react'

import Button from '@/components/Button'

import { useAppDispatch, useAppSelector } from '@/core/store'

import { setEpisode } from '@/modules/serials/serials.actions'

import { getPrevEpisode } from '@/modules/serials/serials.helpers'

interface PrevEpisodeActionProps {
  className?: string
  disabled?: boolean
}
const PrevEpisodeAction: FC<PrevEpisodeActionProps> = ({ className, disabled }) => {
  const dispatch = useAppDispatch()

  const episode = useAppSelector((state) => state.serial.episode)
  const seasons = useAppSelector((state) => state.serial.seasons)
  const episodes = useAppSelector((state) => state.serial.episodes)

  const prevEpisode = useMemo(() => {
    return getPrevEpisode(seasons, episodes, episode)
  }, [seasons, episode, episodes])

  if (!prevEpisode) return null

  const handleAction = () => {
    dispatch(setEpisode(prevEpisode))
  }

  return (
    <Button
      disabled={disabled}
      onButtonClick={handleAction}
      onEnterPress={handleAction}
      buttonClassName={className}
    >
      <>
        <i className='icon-fill-next' style={{ transform: 'rotate(180deg)' }} />
        <span data-title={'title'}>Предыдущая серия</span>
      </>
    </Button>
  )
}

export default PrevEpisodeAction
