import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Slider from '@/components/Slider'
import ContentCard from '@/modules/content/components/ContentCard'
import FetchMoviesByGenreId from '@/modules/films/components/FetchMoviesByGenreId'
import SerialsByGenreIdSlider from '@/modules/serials/component/SerialsByGenreIdSlider'

import { useAppDispatch } from '@/core/store'

import { useGetGenresByContentIdQuery } from '@/modules/content/content.api'
import { setChosenCard } from '@/modules/content/content.actions'

import { ContentPaths, Paths } from '@/core/router/router.paths'

import { ContentModel, ContentType } from '@/models/content.model'

interface Props {
  type: 'movie' | 'serial'
}

const GenresByContentSliders = ({ type, ...rest }: Props) => {
  const { id } = useParams()
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const { data: genres, isLoading: genresLoading } = useGetGenresByContentIdQuery(
    {
      contentId: id || '',
    },
    {
      skip: !id,
    },
  )

  const [count, setCount] = useState(1)

  useEffect(() => {
    setCount(1)
  }, [genres])

  const handleCardPress = useCallback(
    (item: ContentModel) => {
      if (item.id === id || genresLoading) return
      dispatch(setChosenCard(item))
      navigate(
        `${Paths.CONTENT}/${
          item.type === ContentType.MOVIE ? ContentPaths.MOVIES : ContentPaths.SERIALS
        }/${item.id}`,
      )
    },
    [id, genresLoading],
  )

  const sliderSkeleton = useMemo(() => {
    if (!genresLoading) return []

    return (
      <Slider
        key={'genres_slider_skeleton'}
        focusProps={{
          focusable: false,
        }}
      >
        <Slider.Horizontal>
          {Array(5)
            .fill(null)
            .map((_, index) => {
              return <ContentCard key={index} disabled />
            })}
        </Slider.Horizontal>
      </Slider>
    )
  }, [genresLoading])

  const sliders = useMemo(() => {
    if (!genres) return []

    if (type === 'serial') {
      return genres
        .filter((_, index) => index < count)
        .map((el) => {
          return (
            <SerialsByGenreIdSlider
              key={`${id}_${el.id}`}
              genreId={el.id}
              rowProps={{ title: el.pluralTitle }}
              height={447}
              onCardPress={handleCardPress}
              onFocus={() => {
                if (genres.length <= count) return

                setCount((count) => count + 1)
              }}
              {...rest}
            />
          )
        })
    }

    return genres
      .filter((_, index) => index < count)
      .map((el) => {
        return (
          <FetchMoviesByGenreId
            key={`${id}_${el.id}`}
            id={el.id}
            rowProps={{
              title: el.pluralTitle,
            }}
            onCardPress={handleCardPress}
            height={447}
            onFocus={() => {
              if (genres.length <= count) return

              setCount((count) => count + 1)
            }}
            {...rest}
          />
        )
      })
  }, [type, genres, count, handleCardPress, rest])

  return useMemo(() => {
    return [...sliders, sliderSkeleton]
  }, [sliders, sliderSkeleton])
}

export default GenresByContentSliders
