import React, { FC, useEffect, useMemo, useRef } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { InitListActions } from '@/components/List'
import Button from '@/components/Button'
import ListWithScrollbar from '@/components/ListWithScrollbar'

import { useAppDispatch, useAppSelector } from '@/core/store'

import {
  checkNativeFullscreen,
  checkCustomFullscreen,
} from '@/modules/player/helpers/fullscreen.helper'
import { setEpisode } from '@/modules/serials/serials.actions'

import { EpisodeBaseModel } from '@/models/serial.model'
import { useCustomRef } from '@/core/hooks/useCustomRef'
import { useLocation, useNavigate } from 'react-router-dom'
import { ContentPaths, Paths } from '@/core/router/router.paths'
import dayjs from 'dayjs'

interface EpisodesListProps {
  onClose?: () => void
}

const EpisodesList: FC<EpisodesListProps> = ({ onClose }) => {
  const { 0: listActions, 1: setActions } = useCustomRef<InitListActions>({})

  const dispatch = useAppDispatch()

  const serial = useAppSelector((state) => state.content.chosenCard)
  const season = useAppSelector((state) => state.serial.season)
  const episodes = useAppSelector((state) => state.serial.episodes)
  const episode = useAppSelector((state) => state.serial.episode)

  useEffect(() => {
    if (!season) return
    if (!listActions.current.reset) return
    listActions.current.reset()
  }, [season])

  const { pathname } = useLocation()
  const navigate = useNavigate()

  const handlePressClick = async (episode: EpisodeBaseModel) => {
    dispatch(setEpisode(episode))
    onClose?.()
    if (checkNativeFullscreen() || checkCustomFullscreen()) return

    if (pathname.includes('player')) return

    navigate(`${Paths.CONTENT}/${ContentPaths.SERIAL_PLAYER}`, {
      state: { serial, episode, season },
    })
  }

  const preparedEpisodes = useMemo(() => {
    if (!season) return
    return episodes[season.id] || []
  }, [serial, season])

  const dateRef = useRef(dayjs())

  if (!serial || !season || !preparedEpisodes) return null
  return (
    <ListWithScrollbar
      focusProps={{ saveLastFocusedChild: true }}
      className={styles.List}
      onInit={setActions}
    >
      {preparedEpisodes.map((el) => {
        const isNotReleased = !!el.releasedAt && dayjs(el.releasedAt).isAfter(dateRef.current)

        return (
          <Button
            key={el.id}
            // todo: add logic for disable button
            disabled={isNotReleased}
            onEnterPress={() => handlePressClick(el)}
            onButtonClick={() => handlePressClick(el)}
            focusWrapperClassName={styles.EpisodeButtonFocusWrapper}
            buttonClassName={classNames(
              styles.EpisodeButton,
              episode?.id === el.id && styles.Active,
            )}
            autoFocus={el.id === episode?.id}
            focusClassName={styles.Focused}
            style={{
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              '--releasedAt': isNotReleased
                ? `'Дата выхода: ${dayjs(el.releasedAt).format('DD.MM.YYYY')}'`
                : '',
            }}
          >
            <div className={styles.EpisodeButton__Inner}>
              <div className={styles.EpisodeTitle}>Серия {el.number}</div>

              <div className={styles.EpisodeDescription}>
                {el.description || 'Описание отсутствует'}
              </div>
            </div>
          </Button>
        )
      })}
    </ListWithScrollbar>
  )
}

export default EpisodesList
