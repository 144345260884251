import { createApi } from '@reduxjs/toolkit/query/react'

import { MEDIA_API_URL } from '@/core/config'
import { apiBaseQuery } from '@/core/api/api.query'

import { GenreModel } from '@/models/genre.model'
import { ContentModel } from '@/models/content.model'
import { MovieModel } from '@/models/movie.model'
import { SerialModel } from '@/models/serial.model'

import { preparePagination, prepareContentRelations } from '@/core/helpers'

import {
  GetGenresResponse,
  GetGenreArgs,
  GetContentByGenreIdArgs,
  GetMoviesByGenreIdArgs,
  GetSerialsByGenreIdArgs,
  GetMoviesByGenreIdArgsWithPagination,
  GetSerialsByGenreIdArgsWithPagination,
} from '@/modules/genre/genre.types'
import { ApiFetchAllWithPaginationPayload } from '@/core/api/api'

export const genreApi = createApi({
  reducerPath: 'genreApi',
  baseQuery: apiBaseQuery({ baseUrl: `${MEDIA_API_URL}` }),
  tagTypes: [],
  endpoints: (builder) => ({
    getAllGenres: builder.query<GetGenresResponse, void>({
      query: () => ({
        url: '/genres',
        method: 'GET',
        params: {
          relations: 'logo',
        },
      }),
    }),
    getGenre: builder.query<GenreModel, GetGenreArgs>({
      query: ({ genreId }) => ({
        url: `/genres/${genreId}`,
        method: 'GET',
        params: {
          relations: 'logo',
        },
      }),
    }),
    getContentsByGenreId: builder.query<ContentModel[], GetContentByGenreIdArgs>({
      query: ({ genreId, relations: rel }) => {
        const relations = prepareContentRelations(rel || {})
        const params = { relations }

        return {
          url: `/clients/me/genres/${genreId}/contents`,
          method: 'GET',
          params,
        }
      },
    }),
    getSerialsByGenreId: builder.query<SerialModel[], GetSerialsByGenreIdArgs>({
      query: ({ genreId, relations: rel }) => {
        const relations = prepareContentRelations(rel || {}, ['movie'])
        const params = { relations }
        return {
          url: `/clients/me/genres/${genreId}/serials`,
          method: 'GET',
          params,
        }
      },
    }),
    getMoviesByGenreId: builder.query<MovieModel[], GetMoviesByGenreIdArgs>({
      query: ({ genreId, relations: rel }) => {
        const relations = prepareContentRelations(rel || {}, ['movie', 'serial'])
        const params = { relations }
        return {
          url: `/clients/me/genres/${genreId}/movies`,
          method: 'GET',
          params,
        }
      },
    }),

    getMoviesByGenreIdWithPagination: builder.query<
      ApiFetchAllWithPaginationPayload<MovieModel>,
      GetMoviesByGenreIdArgsWithPagination
    >({
      query: ({ genreId, relations: rel, paginate: pag }) => {
        const relations = prepareContentRelations(rel || {}, ['movie', 'serial'])
        const order = 'year:DESC,createdAt:desc'
        const paginate = preparePagination(pag)
        const params = { relations, paginate, order }
        return {
          url: `/clients/me/genres/${genreId}/movies`,
          method: 'GET',
          params,
        }
      },
    }),

    getSerialsByGenreIdWithPagination: builder.query<
      ApiFetchAllWithPaginationPayload<SerialModel>,
      GetSerialsByGenreIdArgsWithPagination
    >({
      query: ({ genreId, relations: rel, paginate: pag }) => {
        const relations = prepareContentRelations(rel || {}, ['movie', 'serial'])
        const paginate = preparePagination(pag)
        const order = 'year:DESC,createdAt:DESC'
        const params = { relations, paginate, order }
        return {
          url: `/clients/me/genres/${genreId}/serials`,
          method: 'GET',
          params,
        }
      },
    }),
    getGenresHasMovies: builder.query<GetGenresResponse, void>({
      query: () => {
        return {
          url: '/clients/me/genres/has/movies',
          method: 'GET',
          params: {
            relations: 'logo',
          },
        }
      },
    }),

    getGenresHasContents: builder.query<GetGenresResponse, void>({
      query: () => {
        return {
          url: '/clients/me/genres/has/contents',
          method: 'GET',
          params: {
            relations: 'logo',
          },
        }
      },
    }),
    getGenresHasSerials: builder.query<GetGenresResponse, void>({
      query: () => {
        return {
          url: '/clients/me/genres/has/serials',
          method: 'GET',
          params: {
            relations: 'logo',
          },
        }
      },
    }),
  }),
})

export const {
  useGetAllGenresQuery,
  useGetGenreQuery,
  useGetContentsByGenreIdQuery,
  useGetMoviesByGenreIdQuery,
  useGetSerialsByGenreIdQuery,
  useGetGenresHasContentsQuery,
  useGetGenresHasMoviesQuery,
  useGetGenresHasSerialsQuery,
  useGetMoviesByGenreIdWithPaginationQuery,
  useGetSerialsByGenreIdWithPaginationQuery,
} = genreApi
