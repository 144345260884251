import React from 'react'
import styles from './styles.module.scss'

import SkeletonView from '@/components/SkeletonView'

import { useAppSelector } from '@/core/store'

const ChosenContentActionsSkeleton = () => {
  const card = useAppSelector((state) => state.content.chosenCard)
  return (
    <div className={styles.ActionsSkeletonWrapper}>
      <div>
        <SkeletonView />
      </div>

      {card && card.trailer && card.trailer.url && (
        <div style={{ width: '155px' }}>
          <SkeletonView />
        </div>
      )}
      {card && card.type === 'SERIAL' && (
        <div style={{ width: '80px' }}>
          <SkeletonView />
        </div>
      )}

      <div>
        <SkeletonView />
      </div>
    </div>
  )
}

export default ChosenContentActionsSkeleton
