import React from 'react'
import styles from '@/modules/films/views/Movie/styles.module.scss'

import PlayChosenMovieAction from '@/modules/films/components/PlayChosenMovieAction'

import { useCheckRights } from '@/modules/content/hooks/useCheckRights'

const PlayChosenMovieActionWithRightsCheck = () => {
  const { rightsLoading, rightsError } = useCheckRights('movie')

  return (
    <PlayChosenMovieAction
      className={styles.ActionButton}
      rightsLoading={rightsLoading}
      rightsError={rightsError}
    />
  )
}

export default PlayChosenMovieActionWithRightsCheck
