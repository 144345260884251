import { EpisodeBaseModel, SeasonBaseModel } from '@/models/serial.model'
import dayjs from 'dayjs'

export const getSeasonEpisodeTitle = (
  episode?: EpisodeBaseModel | null,
  seasons?: SeasonBaseModel[] | null,
) => {
  if (!episode) return ''

  const episodeTitle = `${episode.number} серия`
  const seasonId = episode.seasonId
  const season = seasons?.find((el) => el.id === seasonId)

  if (!season) return episodeTitle
  const seasonTitle = `${season.number} сезон`

  return `${seasonTitle} ${episodeTitle}`
}

export const getCurrentSeason = (
  seasons?: SeasonBaseModel[] | null,
  episode?: EpisodeBaseModel | null,
) => {
  if (!episode || !seasons) return

  const seasonId = episode.seasonId

  const season = seasons?.find((el) => el.id === seasonId)

  if (!season) return

  return season
}

export const getEpisodes = (
  seasons?: SeasonBaseModel[] | null,
  allEpisodes?: Record<string, EpisodeBaseModel[]> | null,
  episode?: EpisodeBaseModel | null,
) => {
  const season = getCurrentSeason(seasons, episode)
  if (!season || !allEpisodes) return

  const episodes = allEpisodes[season.id]

  if (!episodes) return

  return episodes
}

export const getNextSeason = (
  seasons?: SeasonBaseModel[] | null,
  episode?: EpisodeBaseModel | null,
) => {
  if (!episode) return
  const season = getCurrentSeason(seasons, episode)
  if (!season) return
  const seasonNumber = season.number

  return seasons?.find((el) => el.number === seasonNumber + 1)
}

export const getPrevSeason = (
  seasons?: SeasonBaseModel[] | null,
  episode?: EpisodeBaseModel | null,
) => {
  if (!episode) return
  const season = getCurrentSeason(seasons, episode)
  if (!season) return
  const seasonNumber = season.number

  return seasons?.find((el) => el.number === seasonNumber - 1)
}

export const getFirstEpisodeFromSeason = (
  season?: SeasonBaseModel,
  allEpisodes?: Record<string, EpisodeBaseModel[]> | null,
) => {
  if (!season || !allEpisodes) return

  const episodes = allEpisodes[season.id]

  if (!episodes) return

  return episodes[0]
}

export const getLastEpisodeFromSeason = (
  season?: SeasonBaseModel,
  allEpisodes?: Record<string, EpisodeBaseModel[]> | null,
) => {
  if (!season || !allEpisodes) return

  const episodes = allEpisodes[season.id]

  if (!episodes) return

  return episodes[episodes.length - 1]
}

export const getNextEpisodeWithoutReleaseDateCheck = (
  seasons?: SeasonBaseModel[] | null,
  allEpisodes?: Record<string, EpisodeBaseModel[]> | null,
  episode?: EpisodeBaseModel | null,
) => {
  const episodes = getEpisodes(seasons, allEpisodes, episode)
  if (!episodes || !episode) return
  const episodeNumber = episode.number

  const episodeIndex = episodes.findIndex((el) => el.number === episodeNumber)

  if (episodeIndex === -1) return

  const next = episodes.find((_, index) => index === episodeIndex + 1)

  if (next) {
    return next
  }

  const nextSeason = getNextSeason(seasons, episode)
  if (!nextSeason) return

  return getFirstEpisodeFromSeason(nextSeason, allEpisodes)
}

export const getNextEpisode = (
  seasons?: SeasonBaseModel[] | null,
  allEpisodes?: Record<string, EpisodeBaseModel[]> | null,
  episode?: EpisodeBaseModel | null,
) => {
  const episodeWithoutReleaseDateCheck = getNextEpisodeWithoutReleaseDateCheck(
    seasons,
    allEpisodes,
    episode,
  )
  if (!episodeWithoutReleaseDateCheck) return

  const isNotReleased =
    !!episodeWithoutReleaseDateCheck.releasedAt &&
    dayjs(episodeWithoutReleaseDateCheck.releasedAt).isAfter(dayjs())

  if (isNotReleased) return

  return episodeWithoutReleaseDateCheck
}

export const getPrevEpisode = (
  seasons?: SeasonBaseModel[] | null,
  allEpisodes?: Record<string, EpisodeBaseModel[]> | null,
  episode?: EpisodeBaseModel | null,
) => {
  const episodes = getEpisodes(seasons, allEpisodes, episode)
  if (!episodes || !episode) return
  const episodeNumber = episode.number
  const episodeIndex = episodes.findIndex((el) => el.number === episodeNumber)

  if (episodeIndex === -1) return
  const prev = episodes.find((_, index) => index === episodeIndex - 1)

  if (prev) return prev

  const prevSeason = getPrevSeason(seasons, episode)

  if (!prevSeason) return

  return getLastEpisodeFromSeason(prevSeason, allEpisodes)
}
