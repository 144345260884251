import React, { useEffect } from 'react'

import styles from './styles.module.scss'

import Slider from '@/components/Slider'
import DefaultFocusWrapper from '@/components/Slider/DefaultFocusWrapper'
import ActorsAndGenres from '@/modules/content/components/ActorsAndGenres'
import ContentFetcher from '@/modules/content/components/ContentFetcher'
import GenresByContentSliders from '@/modules/content/components/GenresByContentSliders'
import FetchSeasons from '@/modules/serials/component/FetchSeasons'
import ChosenSerialActionsWithSkeleton from '@/modules/serials/component/ChosenSerialActionsWithSkeleton'
import FetchViewInfoAndPresetSeasonAndEpisode from '@/modules/serials/component/FetchViewInfoAndPresetSeasonAndEpisode'

const Serial = () => {
  useEffect(() => {
    const desc = document.getElementById('content-description')
    if (desc) {
      desc.dataset.full = 'true'
    }
    return () => {
      if (desc) {
        desc.dataset.full = 'false'
      }
    }
  }, [])

  return (
    <div id={'serial-page'} className={styles.Serial}>
      <div className={styles.Serial__Footer}>
        <Slider className={styles.FocusWrapper}>
          <Slider.Vertical>
            {[
              <DefaultFocusWrapper key={0} withoutChildProps className={styles.ActionsFocusWrapper}>
                <ActorsAndGenres className={styles.AdditionalInfo} />
                <ChosenSerialActionsWithSkeleton />
              </DefaultFocusWrapper>,
              <GenresByContentSliders key={'sliders'} type={'serial'} />,
            ]}
          </Slider.Vertical>
        </Slider>
      </div>
      <div id={'trailer'} />
      <div id={'serial-player'} />
      <ContentFetcher />
      <FetchSeasons />
      <FetchViewInfoAndPresetSeasonAndEpisode />
    </div>
  )
}

export default Serial
