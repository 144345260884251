import React, { FC } from 'react'

import { getPreparedActors, getPreparedGenresTitles } from '@/modules/content/content.helpers'

import { useAppSelector } from '@/core/store'
import {
  useGetActorsByContentIdQuery,
  useGetGenresByContentIdQuery,
} from '@/modules/content/content.api'
import SkeletonView from '@/components/SkeletonView'

interface ActorsAndGenresProps {
  className?: string
}

const ActorsAndGenres: FC<ActorsAndGenresProps> = ({ className }) => {
  const contentId = useAppSelector((state) => state.content.chosenCard?.id)

  const { data: actors, isLoading: actorsLoading } = useGetActorsByContentIdQuery(
    { contentId: contentId || '' },
    {
      skip: !contentId,
    },
  )

  const { data: genres, isLoading: genresLoading } = useGetGenresByContentIdQuery(
    { contentId: contentId || '' },
    {
      skip: !contentId,
    },
  )

  return (
    <>
      <div
        className={className}
        style={{
          minHeight: '95px',
        }}
      >
        {actorsLoading && (
          <>
            <TitleSkeleton />
            <ContentSkeleton />
          </>
        )}
        {!actorsLoading && (
          <>
            <b style={{ fontSize: '26px' }}>Актерский состав:</b>
            <br />
            {getPreparedActors(actors) || 'Нет информации'}
          </>
        )}
      </div>
      <div
        className={className}
        style={{
          minHeight: '95px',
        }}
      >
        {genresLoading && (
          <>
            <TitleSkeleton style={{ width: '150px' }} />
            <ContentSkeleton />
          </>
        )}
        {!genresLoading && (
          <>
            <b style={{ fontSize: '26px' }}>Жанры:</b>
            <br />
            {!genresLoading && (getPreparedGenresTitles(genres) || 'Нет информации')}
          </>
        )}
      </div>
    </>
  )
}

interface Props {
  style?: React.CSSProperties
}

const TitleSkeleton = ({ style }: Props) => {
  return (
    <div
      style={{
        width: '225px',
        height: '30px',
        marginBottom: '5px',
        ...style,
      }}
    >
      <SkeletonView />
    </div>
  )
}

const ContentSkeleton = ({ style }: Props) => {
  return (
    <div
      style={{
        width: '100%',
        height: '60px',
        ...style,
      }}
    >
      <SkeletonView />
    </div>
  )
}

export default ActorsAndGenres
