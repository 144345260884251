import React, { useEffect, useState } from 'react'
import styles from '@/modules/films/views/Movie/styles.module.scss'

import ChosenContentActionsSkeleton from '@/modules/content/components/ChosenContentActionsSkeleton'
import PlayChosenMovieActionWithRightsCheck from '@/modules/films/components/PlayChosenMovieActionWithRightsCheck'
import TrailerAction from '@/modules/content/components/TrailerAction'
import FavoritesAction from '@/modules/favorites/components/FavoritesAction'

import { subscribeToContentStatusEvent } from '@/core/customEvents/content'

import { ContentType } from '@/models/content.model'

const ChosenMovieActionsWithSkeleton = () => {
  const [showSkeleton, setShowSkeleton] = useState(true)

  useEffect(() => {
    const handleContentStatus = (e: CustomEvent) => {
      setShowSkeleton(!e.detail.status)
    }

    return subscribeToContentStatusEvent(handleContentStatus)
  }, [])

  if (showSkeleton) {
    return <ChosenContentActionsSkeleton />
  }

  return (
    <div className={styles.ActionsContainer}>
      <PlayChosenMovieActionWithRightsCheck />
      <TrailerAction buttonClassName={styles.ActionButton} />
      <FavoritesAction className={styles.ActionButton} favoritesContentType={ContentType.MOVIE} />
    </div>
  )
}

export default ChosenMovieActionsWithSkeleton
