import { createApi } from '@reduxjs/toolkit/query/react'

import { MEDIA_API_URL } from '@/core/config'
import { apiBaseQuery } from '@/core/api/api.query'
import { prepareContentRelations, preparePagination } from '@/core/helpers'

import { PartnerModel } from '@/models/partner.model'
import { ContentModel, ContentRelations } from '@/models/content.model'
import { ApiFetchAllWithPaginationPayload, PaginateArgs } from '@/core/api/api'
import { UnionContentType } from '@/modules/promotes/promotes.api'
import { MediaPartnerId } from '@/modules/partner/partner.constants'

export const partnerApi = createApi({
  reducerPath: 'partnerApi',
  baseQuery: apiBaseQuery({ baseUrl: `${MEDIA_API_URL}/` }),
  tagTypes: [],
  endpoints: (builder) => ({
    getAllPartners: builder.query<PartnerModel[], void>({
      query: () => ({
        url: 'clients/me/partners',
        method: 'GET',
        params: {
          relations: 'logo',
        },
      }),
    }),

    getContentByPartnerId: builder.query<
      ApiFetchAllWithPaginationPayload<ContentModel>,
      {
        type?: UnionContentType
        partnerId: MediaPartnerId
        relations?: Partial<ContentRelations>
        paginate?: Partial<PaginateArgs>
      }
    >({
      query: ({ partnerId, type = 'contents', relations: rel, paginate: pag }) => {
        const relations = prepareContentRelations(rel || {}, ['serial', 'movie'])
        const paginate = preparePagination(pag)
        const params = { relations, paginate }
        return { url: `clients/me/partners/${partnerId}/${type}`, method: 'GET', params }
      },
    }),
  }),
})

export const { useGetAllPartnersQuery, useGetContentByPartnerIdQuery } = partnerApi
