import { PartnerBaseModel } from '@/models/partner.model'
import { TrailerBaseModel } from '@/models/trailer.model'
import { CountryBaseModel } from '@/models/country.model'
import { BasePosterModel } from '@/models/poster.model'
import { AgeModel } from '@/models/age.model'
import { BackgroundModel } from '@/models/background.model'
import { ContentPromoteModel } from '@/models/contentPromote.model'
import { SyncMediaViewModelWithResource } from '@/models/sync.media.view.model'

export enum ContentType {
  MOVIE = 'MOVIE',
  SERIAL = 'SERIAL',
}

export enum ContentStatus {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
}

export interface ContentBaseModel {
  createdAt: string
  updatedAt: string
  id: string
  title: string
  alias: string
  slug: string
  description: string
  year: number
  type: ContentType
  status: ContentStatus
  partnerContentStatus: ContentStatus
  isFree: boolean
  isUhd: boolean
  ratingImdb: string | null
  ratingKinopoisk: string | null
}

export interface ContentModel extends ContentBaseModel {
  // actors?: ActorBaseModel[] | null
  age?: AgeModel | null
  partner?: PartnerBaseModel | null
  trailer?: TrailerBaseModel | null
  // genres?: GenreBaseModel[] | null
  countries?: CountryBaseModel[] | null
  posters?: BasePosterModel[] | null
  backgrounds?: BackgroundModel[] | null
  // promo?: PromoModel | null
}

export interface ContentWithPromoteModel extends ContentModel {
  promoteContent?: ContentPromoteModel
}

export interface ContentWithViewModel extends ContentModel {
  view: SyncMediaViewModelWithResource
}

export type ContentRelationsKeys =
  | 'movie'
  | 'serial'
  | 'trailer'
  | 'partner'
  | 'countries'
  | 'posters.verticalImage,posters.horizontalImage'
  | 'age'
  | 'backgrounds.image'

export interface ContentRelations {
  age: boolean
  movie: boolean
  serial: boolean
  trailer: boolean
  partner: boolean
  countries: boolean
  posters: boolean
  backgrounds: boolean
}
